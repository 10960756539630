exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-program-jsx": () => import("./../../../src/pages/program.jsx" /* webpackChunkName: "component---src-pages-program-jsx" */),
  "component---src-pages-studio-jsx": () => import("./../../../src/pages/studio.jsx" /* webpackChunkName: "component---src-pages-studio-jsx" */),
  "component---src-pages-using-ssr-jsx": () => import("./../../../src/pages/using-ssr.jsx" /* webpackChunkName: "component---src-pages-using-ssr-jsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

